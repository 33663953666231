import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main-view",
    component: () => import('../views/MainView.vue')
  },
  {
    path: "/photo",
    name: "photo-view",
    component: () => import('../views/PhotoView.vue')
  },
  {
    // 某个电影的详细信息界面
    path: '/photo/album/:id',
    component: () => import('../views/photo/AlbumInfo.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: "page-error-404-view",
    component: () => import('../views/PageError404.vue')
  },
  {
    path:"/notfound",
    component: () => import('../views/PageError404.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   // to: Route: 即将要进入的目标路由对象
//   // from: Route: 当前导航正要离开的路由
//   // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

//   // 检查用户是否已经登录
//   if (from.path == '' && to.path !== '/login') {
//     // 如果用户未登录，并且访问的不是登录页面，则重定向到登录页面
//     next('/login')
//   } else {
//     // 如果用户已经登录，或者访问的是登录页面，则继续导航
//     next()
//   }
// })

export default router
