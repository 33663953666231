import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/global.css'
import 'animate.css'
import moment from 'moment'
import VueEasyLightbox from 'vue-easy-lightbox'

import axios from 'axios'
// 访问环境变量
console.log(process.env.VUE_APP_API_HOST);


axios.defaults.baseURL = process.env.VUE_APP_API_HOST;



// 设置默认语言为中文
moment.locale('zh-cn');

const app = createApp(App);
app.use(store)
app.use(router);
app.use(VueEasyLightbox)
app.mount('#app');
app.config.globalProperties.$http = axios;
app.config.globalProperties.$moment = moment;

